<template>
    <div class="cart-box">
        <div class="cart-wrap">
            <div class="cart-title">
                <div class="title">
                    购物车
                </div>
                <div class="warp-box">
                    <ul>
                        <li>
                            <div class="img">
                                <img src="../assets/img/cart-showIcon.png" alt="">
                            </div>
                            <div class="text">购物车</div>
                        </li>
                        <li>
                            <div class="img2">
                                <img src="../assets/img/cart-confirmIcon.png" alt="">
                            </div>
                            <div class="text">确认订单</div>
                        </li>
                        <li>
                            <div class="img3">
                                <img src="../assets/img/cart-paymentIcon.png" alt="">
                            </div>
                            <div class="text">支付订单</div>
                        </li>
                        <li>
                            <div class="img4">
                                <img src="../assets/img/cart-successIcon.png" alt="">
                            </div>
                            <div class="text">完成</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="cart-content">
                <div class="content-title">
                    <ul class="content-ul">
                        <li class="col-1">
                            <span class="checkbox" :class="{'checked':isSelectAll}" @click="selectProduct(isSelectAll)"></span>全选
                        </li>
                        <li class="col-2">
                            商品信息
                        </li>
                        <li class="col-3">
                            单价
                        <!-- </li>
                        <li class="col-4">
                            数量
                        </li> -->
                        <li class="col-5">
                            小计
                        </li>
                        <li class="col-6">
                            操作
                        </li>
                    </ul>
                </div>
                <div>
                    <ul class="cart-item-list">
                        <li class="cart-item" v-for="(item,index) in goodsList" :key="index">
                                <!-- item.select=!item.select -->
                                <div class="item-check">
                                    <span class="checkbox" :class="{'checked':item.select}" @click='selectClick(item)'></span>
                                </div>
                                <div class="item-name">
                                    <img :src="item.logo">
                                    <span>{{item.courseName}}</span>
                                </div>
                                <div class="item-price">￥{{item.price}}</div>
                                <!-- <div class="item-num">
                                    <div class="num-box">
                                        <a href="javascript:;">-</a>
                                    </div>
                                    <span>1</span>
                                    <div class="num-box">
                                        <a href="javascript:;">+</a>
                                    </div>
                                </div> -->
                                <div class="item-total">￥{{item.money}}</div>
                                <div class="item-del" @click="delSlide(item.courseId)">删除</div>

                        </li>
                    </ul>

                </div>
                <div class="buttom-box">
                   <div class="toggle-box">
                        <div class="toggle">
                            <span class="checkbox" :class="{'checked':isSelectAll}"  @click="selectProduct(isSelectAll)"></span>全选
                        </div>
                        <div class="item-del" @click="deleteProduct">删除</div>
                   </div>
                   <div class="next-box">
                        合计：<span>￥{{getTotal.totalPrice}}</span>元
                        <a href="javascript:;" class="btn" @click="order">去结算</a>
                   </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cart',
    data() {
        return {
            goodsList: [],
            allChecked: false, // 是否全选
            idList: []
        }
    },
    mounted() {
        this.getCartList();
    },
    metaInfo: {
        title: '购物车-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    computed:{
        // 检测是否全选
      isSelectAll:function(){
          //如果goodsList中每一条数据的select都为true,就返回true,否则返回false
        return this.goodsList.every(val => val.select)
      },
      getTotal:function(){
        this.$store.commit('saveCartCount',this.goodsList.length);
        // 获取list中select为true的数据
        var _proList = this.goodsList.filter(val => val.select);
        var totalPrice = 0;
        // var totalNum = 0;
        for (var i = 0; i < _proList.length; i++) {
            totalPrice += _proList[i].price;
        }
        // 选择产品的件数就是_proList.lenth，总价就是totalPrice
        
        return{totalPrice:totalPrice}
      }
    },
    methods: {
        selectClick(item) {
            item.select=!item.select;
            this.idList.push(item.courseId)
        },
        // 全选与取消全选
        selectProduct:function(_isSelect){
            //遍历goodsList,全部取反
            for (var i = 0; i < this.goodsList.length; i++) {
                this.goodsList[i].select = !_isSelect;
                this.idList.push(this.goodsList[i].courseId);
                this.idList = [...new Set(this.idList)];
            }
        },
        deleteProduct() {
            let delList = [];
            for(var i in this.goodsList) {
                delList.push(this.goodsList[i].courseId);
            }
            delList = [...new Set(delList)];
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/delete.html',
                data: {
                    courseIds: delList
                }
            }).then(() => {
                this.$message.success('已删除');
                this.getCartList();
            })
        },
        // 删除
        delSlide(id) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/delete.html',
                data: {
                    courseIds: id
                }
            }).then(() => {
                this.$message.success('已删除');
                this.getCartList();
            })
        },
        order() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/list.html',
                data: {
                    courseIds: this.idList
                }
            }).then((res) => {
                if(res.data.code == 1000) {
                    this.$message.warning('请添加课程');
                } else {
                    sessionStorage.setItem('idList',JSON.stringify(this.idList));
                    this.$router.push('/orderConfirm');
                }
                
            })
            // this.$router.push('/orderConfirm');
        },
        getCartList() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/list.html'
            }).then((res) => {
                let _this = this;
                if(res.data.code == 1000) {
                    _this.$message.warning('请添加课程');
                    _this.goodsList = [];
                } else {
                    res.data.result.list.map(function(item){    //map:'键值对'
                        _this.$set(item,'select',false)      //往item添加select属性，默认为true
                    })
                    _this.goodsList = res.data.result.list;
                }
                
            })
        }
    }
}
</script>

<style lang="scss">
    .cart-box {
        padding-top: 90px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 100%;
        min-width: 1200px;
        background: #EEEEEE;
        .cart-wrap {
            margin: 0 auto;
            width: 1200px;
            background: #ffffff;
            .cart-title {
                padding-left: 30px;
                padding-right: 30px;
                margin-bottom: 40px;
                display: flex;
                justify-content: space-between;
                height: 118px;
                border-bottom: 1px solid #EEEEEE;
                .title {
                    line-height: 118px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    color: #444444;
                }
                .warp-box {
                    padding-top: 30px;
                    ul {
                        display: flex;
                        li {
                            text-align: center;
                            .img {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .img2 {
                                margin-bottom: 10px;
                                width: 94px;
                                height: 36px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .img3 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .img4 {
                                margin-bottom: 10px;
                                width: 93px;
                                height: 36px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .cart-content {
                .content-title {
                    padding-left: 35px;
                    padding-right: 35px;
                    box-sizing: border-box;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    width: 1139px;
                    height: 50px;
                    background: #F6F6F6;
                    .checkbox{
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        border: 1px solid #E5E5E5;
                        vertical-align: middle;
                        margin-right: 17px;
                        cursor:pointer;
                        &.checked{
                            background:url('../assets/img/cartChoiceIcon.png') no-repeat center;
                            // background: blue;
                            border:none;
                        }
                    }
                    .content-ul{
                        display:flex;
                        line-height: 50px;
                        .col-1 {
                            margin-right: 191px;
                        }
                        .col-2 {
                            margin-right: 258px;
                        }
                        .col-3 {
                            margin-right: 195px;
                        }
                        .col-4 {
                            margin-right: 142px;
                        }
                        .col-5 {
                            margin-right: 185px;
                        }
                    }
                }
                .cart-item-list{
                    background: #ffffff;
                    .cart-item{
                        display:flex;
                        align-items:center;
                        margin: 0 auto;
                        padding-left: 35px;
                        padding-right: 35px;
                        margin-bottom: 20px;
                        box-sizing: border-box;
                        width: 1139px;
                        height: 140px;
                        border: 1px solid #EEEEEE;
                        font-size:16px;
                        .item-check{
                            margin-right: 28px;
                            .checkbox{
                                display: inline-block;
                                width: 22px;
                                height: 22px;
                                border: 1px solid #E5E5E5;
                                vertical-align: middle;
                                margin-right: 17px;
                                cursor:pointer;
                                &.checked{
                                    // background:url('/imgs/icon-gou.png') #FF6600 no-repeat center;
                                    background:url('../assets/img/cartChoiceIcon.png') no-repeat center;
                                    border:none;
                                }
                            }
                        }
                        .item-name{
                            font-size: 18px;
                            color: #333333;
                            display: flex;
                            align-items: center;
                            margin-right: 27px;
                            img{
                                width: 160px;
                                height: 100px;
                                vertical-align:middle;
                            }
                            span{
                                margin-left: 30px;
                                width: 260px;
                            }
                        }
                        .item-price{
                            width: 51px;
                            margin-right: 158px;
                            color:#FF5A74;
                            text-align: center;
                        }
                        .item-num{
                            margin-right: 92px;
                            .num-box{
                                display:inline-block;
                                width: 22px;
                                height: 22px;
                                line-height:22px;
                                border:1px solid #BFBFBF;
                                font-size:18px;
                                text-align: center;
                                a{
                                    display:inline-block;
                                    color:#BFBFBF;
                                }
                            }
                            span{
                                display:inline-block;
                                width: 51px;
                                text-align: center;
                                color:#333333;
                            }
                        }
                        .item-total{
                            width: 51px;
                            text-align: center;
                            margin-right: 178px;
                            color:#333333;
                        }
                        .item-del{
                            color: #FF5A74;
                            cursor:pointer;
                        }
                    }
                }
            }
            .buttom-box {
                padding-left: 35px;
                // padding-right: 35px;
                padding-bottom: 35px;
                box-sizing: border-box;
                margin: 0 auto;
                width: 1139px;
                display: flex;
                justify-content: space-between;
                .toggle-box {
                    display: flex;
                    padding-top: 14px;
                    .toggle {
                        margin-right: 19px;
                        .checkbox{
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            border: 1px solid #E5E5E5;
                            vertical-align: middle;
                            margin-right: 17px;
                            cursor:pointer;
                            &.checked{
                                // background:url('/imgs/icon-gou.png') #FF6600 no-repeat center;
                                background:url('../assets/img/cartChoiceIcon.png') no-repeat center;
                                border:none;
                            }
                        }
                    }
                    .item-del {
                        line-height: 22px;
                        cursor: pointer;
                    }
                }
                .next-box {
                    font-size:14px;
                    color:#666666;
                    span{
                        font-size:24px;
                        color: #FF5A74;
                    }
                    a{
                        margin-left: 40px;
                        display: inline-block;
                        width: 143px;
                        height: 45px;
                        border: 1px solid #FF5A74;
                        line-height:45px;
                        font-size:16px;
                        text-align: center;
                        color: #FF5A74;
                    }
                }
            }
        }
    }
</style>